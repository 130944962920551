.container {
    display: flex;

}

.logo {
    height: 50px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

