
html, body {
    font-family: sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

html, body {
    height: 100vh
}

#root {
    height: 100vh;
}
.indicator {
    z-index: 999999;
    /*background: rgba(0, 17, 25, 0.05);*/
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.main-container {
    display: flex;
    flex-direction: column;
    height: 100vh;

}

.content {
    display: flex;
    height: 100vh;
    width: 100%;
    flex: 1 1;
    overflow: hidden;
}

.side-menu {

}

.site-content {
    height: 100%;
    width: 100%;
    margin: 25px;
    overflow: hidden;
}

.outer-content {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
div.DraftEditor-root {

    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 20px;
    font-size: 18px;
    font-family: 'calibri', sans-serif;
    width: 100%;
    height: 60vh;

}

div.DraftEditor-editorContainer {
    max-width: 100%;
}

button.MuiButtonBase-root-91 {
    border-style: solid;
    padding: 5px;
}
.container {
    display: flex;

}

.logo {
    height: 50px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}


