.main-container {
    display: flex;
    flex-direction: column;
    height: 100vh;

}

.content {
    display: flex;
    height: 100vh;
    width: 100%;
    flex: 1;
    overflow: hidden;
}

.side-menu {

}

.site-content {
    height: 100%;
    width: 100%;
    margin: 25px;
    overflow: hidden;
}

.outer-content {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}