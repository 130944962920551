div.DraftEditor-root {

    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 20px;
    font-size: 18px;
    font-family: 'calibri', sans-serif;
    width: 100%;
    height: 60vh;

}

div.DraftEditor-editorContainer {
    max-width: 100%;
}

button.MuiButtonBase-root-91 {
    border-style: solid;
    padding: 5px;
}